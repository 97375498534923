import tippy from 'tippy.js';

export default class Tippy {
    $el;

    constructor($el) {
        this.$el = $el;

        tippy(this.$el, {
            animation: 'shift-away',
            arrow: true,
            theme: 'vds',
        });
    }
}
