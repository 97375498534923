export default class App {
    bindings;

    constructor(bindings = []) {
        this.initListeners();
        this.bind(bindings);
    }

    bind(bindings = [], $root = document) {
        for (const binding of bindings) {
            const selector = binding.selector ?? `.js-${binding.name}`;

            $root.querySelectorAll(selector).forEach($el => {
                // Maak leeg __APP__ object aan als het nog niet bestaat
                $el.__APP__ ||= {};

                if ($el.__APP__[binding.name] === undefined) {
                    // Maak component instance en voeg toe aan het __APP__ object van het element
                    $el.__APP__[binding.name] = new binding.component($el, binding.options ?? {});
                }
            });
        }

        // Sla alle bindings op voor herinitialisatie bij DOM updates
        this.bindings = bindings;
    }

    initListeners() {
        // Pas componenten toe op nieuw toegeovoegde DOM elementen
        const mutationObserver = new MutationObserver(mutations => {
            let targets = [];

            mutations.forEach(mutationRecord => {
                if (mutationRecord.target instanceof HTMLElement) {
                    if (!targets.includes(mutationRecord.target)) {
                        this.bind(this.bindings, mutationRecord.target);
                        targets.push(mutationRecord.target);
                    }
                }
            });
        });

        mutationObserver.observe(document.documentElement, {
            childList: true,
            subtree: true,
        });
    }
}
