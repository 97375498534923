import App from './App';
import Box from './components/Box';
import Cookies from 'js-cookie';
import Icon from './components/Icon';
import JobCalendar from './components/JobCalendar';
import Menu from './components/Menu';
import Modal from './components/Modal';
import Maps from './components/Maps';
import Password from './components/Password';
import PickList from './components/PickList';
import Planner from './components/Planner';
import Scanner from './components/Scanner';
import ScannerModal from './components/ScannerModal';
import SyncSalesforce from './components/SyncSalesforce';
import Tabs from './components/Tabs';
import ToggleFullScreen from './components/ToggleFullScreen';
import Tippy from './components/Tippy';
import Toast from './components/Toast';
import WeekPicker from './components/WeekPicker';
import XhrForm from './components/XhrForm';
import XhrModal from './components/XhrModal';


window.addEventListener('DOMContentLoaded', () => {
    const bindings = [
        {
            component: Box,
            name: 'box',
        },
        {
            component: Cookies,
            name: 'js-cookie',
        },
        {
            component: Icon,
            name: 'icon',
        },
        {
            component: JobCalendar,
            name: 'jobCalendar',
        },
        {
            component: Menu,
            name: 'menu',
        },
        {
            component: Maps,
            name: 'maps',
        },
        {
            component: Modal,
            name: 'modal',
        },
        {
            component: Password,
            name: 'password',
        },
        {
            component: PickList,
            name: 'pickList',
        },
        {
            component: Planner,
            name: 'planner',
        },
        {
            component: Scanner,
            name: 'scanner',
        },
        {
            component: ScannerModal,
            name: 'scannerModal',
        },
        {
            component: SyncSalesforce,
            name: 'syncSalesforce',
        },
        {
            component: Tabs,
            name: 'tabs',
        },
        {
            component: Toast,
            name: 'toast',
        },
        {
            component: ToggleFullScreen,
            name: 'toggleFullScreen',
        },
        {
            component: Tippy,
            name: 'tippy',
        },  
        {
            component: WeekPicker,
            name: 'weekPicker',
        },
        {
            component: XhrForm,
            name: 'xhrForm',
        },
        {
            component: XhrModal,
            name: 'xhrModal',
        },
    ];

    if (window.__APP__ instanceof App) {
        window.__APP__.bind(bindings);
    } else {
        window.__APP__ = new App(bindings);
    }
});
