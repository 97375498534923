export default class XhrModal {
    $el;

    constructor($el) {
        this.$el = $el;
        this.initListeners();
    }

    initListeners() {
        this.$el.addEventListener('click', this.onClick.bind(this), false);
    }

    onClick(e) {
        e.preventDefault();
        const url = this.$el.href ?? this.$el.dataset.xhrModalUrl ?? null;

        // Add temporary overlay with spinner
        const $overlay = document.createElement('div');
        $overlay.classList.add('xhrModal');
        $overlay.innerHTML = '<i class="fa-2x fa-duotone fa-spin fa-spinner-third"></i>';
        document.body.appendChild($overlay);

        fetch(url)
            .then(response => response.text())
            .then(data => {
                const $div = document.createElement('div');
                $div.innerHTML = data;
                const $modal = $div.querySelector('.js-modal');

                if ($modal) {
                    $modal.dataset.modalXhr = true;
                    $overlay.remove();
                    document.body.appendChild($modal);
                    
                    requestAnimationFrame(() => {
                        $modal.classList.add('is-open');

                        const openHook = $modal.dataset.modalOpenHook ?? null;

                        if (openHook) {
                            window[openHook]($modal);
                        }
                    });
                }
            })
            .catch(error => {
                console.error('Error:', error);
                $overlay.remove();
            });
    }
}
