export default class Modal {
    $el;
    $$close;
    $overlay;
    closeHook;
    id;
    openHook;

    constructor($el) {
        this.$el = $el;
        this.$$close = $el.querySelectorAll('.js-modalClose, .close');
        this.$overlay = $el.querySelector('.js-modalOverlay');
        this.id = $el.id;
        this.closeHook = $el.dataset.modalCloseHook ?? null;
        this.openHook = $el.dataset.modalOpenHook ?? null;

        this.initListeners();
    }

    close() {
        this.$el.dispatchEvent(new CustomEvent('modal:close'));

        // Roep close hook aan indien deze bestaat (voordat formulier wordt gereset)
        if (this.closeHook && typeof window[this.closeHook] === 'function') { 
            window[this.closeHook](this.$el);
        }

        // Reset eventuele formulieren in de modal
        const $$form = this.$el.querySelectorAll('form');

        for (const $form of $$form) {
            $form.reset();
        }

        // Verberg modal
        this.$el.classList.remove('is-open');
        this.$el.style.display = 'none';

        // Verwijder modal wanneer het een XHR modal is
        if (this.$el.dataset.modalXhr) {
            requestAnimationFrame(() => {
                this.$el.remove();
            });
        }
    }

    initListeners() {
        if (this.id) {
            // Open modal wanneer er op een element met href="#modal-{id}" of data-modal="{id}" wordt geklikt
            const $$trigger = document.querySelectorAll(`[href="#${this.id}"], [data-modal="${this.id}"]`);

            for (const $trigger of $$trigger) {
                $trigger.addEventListener('click', this.onTriggerClick.bind(this), false);
            }
        } else {
            console.error('Modal ID attribute is missing', this.$el);
        }

        // Sluit modal wanneer er op een .js-modalClose of .close element wordt geklikt
        for (const $close of this.$$close) {
            $close.addEventListener('click', this.onCloseClick.bind(this), false);
        }

        // Sluit modal wanneer er op de overlay wordt geklikt
        this.$overlay.addEventListener('click', (e) => {
            if (e.currentTarget === e.target) {
                this.close();
            }
        }, false);

        // Sluit modal wanneer er op Esc wordt gedrukt
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                this.close();
            }
        });
    }

    onCloseClick() {
        this.close();
    }

    onTriggerClick(e) {
        e.preventDefault();

        this.$el.classList.add('is-open');
        this.$el.style.display = 'flex';
        this.$el.dispatchEvent(new CustomEvent('modal:open'));

        // Roep open hook aan indien deze bestaat
        if (this.openHook && typeof window[this.openHook] === 'function') { 
            window[this.openHook](this.$el);
        }
    }
}
