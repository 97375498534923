export default class XhrForm {
    $el;
    method;
    request;
    url;

    constructor($el) {
        this.$el = $el;
        this.method = this.$el.getAttribute('method') ?? 'post';
        this.request = null;
        this.url = this.$el.getAttribute('action');

        this.initListeners();
    }

    initListeners() {
        this.$el.addEventListener('submit', this.onSubmit.bind(this));
    }

    async onSubmit(e) {
        e.preventDefault();
        this.resetErrors();

        if (this.isLoading) {
            return;
        }

        if (this.$el.dataset.xhrFormConfirm) {
            if (!window.confirm(this.$el.dataset.xhrFormConfirm)) {
                return false;
            }
        }

        this.isLoading = true;
        this.isSuccess = false;

        const res = await fetch(this.url, {
            method: this.method,
            body: new FormData(this.$el),
        });
        const data = await res.json();
        this.isLoading = false;

        if (res.ok) {
            this.isSuccess = true;

            if (data.redirect ?? null) {
                window.location = data.redirect;
            }

            if (data.refresh ?? null) {
                window.location.reload();
            }
        } else {
            if (res.status === 422) {
                if (data.errors ?? null) {
                    this.displayErrors(data.errors);
                }
            } else {
                console.error('Error:', res.status, res.statusText);
            }
        }

        if (data.toast ?? null) {
            document.dispatchEvent(new CustomEvent('toast', { detail: data.toast }));
        }
    }

    displayErrors(errors) {
        this.resetErrors();

        for (const key in errors) {
            const $input = this.$el.querySelector(`[name="${key}"]`);

            if ($input) {
                const $error = document.createElement('div');
                $error.className = 'field__error';
                $error.textContent = errors[key];

                const $field = $input.closest('.field');
                $field.classList.add('has-error');
                $field.append($error);
            }
        }
    }

    resetErrors() { 
        this.$el.querySelectorAll('.field__error').forEach(($error) => {
            const $field = $error.closest('.field');

            if ($field) {
                $field.classList.remove('has-error');
                $error.remove();
            }
        });
    }

    /**
     * Getters & setters
     */

    get isLoading() {
        return this.$el.classList.contains('is-loading');
    }

    set isLoading(isLoading) {
        this.$el.querySelectorAll('button[type="submit"]').forEach(($submit) => {
            if (isLoading) {
                $submit.setAttribute('disabled', '');
            } else {
                $submit.removeAttribute('disabled');
            }
        });

        return this.$el.classList.toggle('is-loading', isLoading);
    }

    get isSuccess() {
        return this.$el.classList.contains('is-success');
    }

    set isSuccess(isSuccess) {
        return this.$el.classList.toggle('is-success', isSuccess);
    }
}
