export default class ToggleFullScreen {
    $el;

    constructor($el) {
        this.$el = $el;

        this.initListeners();
    }

    initListeners() {
        this.$el.addEventListener('click', () => {
            document.body.classList.toggle('is-fullScreen');
        })
    }
}
