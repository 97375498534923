export default class JobCalendar {
    $el;
    $inputMultiDate;
    $inputMultiDateCancel;
    $inputMultiDateLoad;
    $inputMultiDateVisit;
    $monthNavNext;
    $monthNavPrev;
    $container;
    activeMonth;
    activeYear;
    dayNames;
    mode;
    month;
    monthNames;
    multiDate;
    multiDateVisit;
    multiDateCancel;
    multiDateLoad;

    constructor($el) {
        this.$el = $el;
        this.$$mode = this.$el.querySelectorAll('.js-jobCalendarMode');
        this.$inputMultiDate = this.$el.querySelector('.js-jobCalendarInputMultiDate');
        this.$inputMultiDateCancel = this.$el.querySelector('.js-jobCalendarInputMultiDateCancel');
        this.$inputMultiDateLoad = this.$el.querySelector('.js-jobCalendarInputMultiDateLoad');
        this.$inputMultiDateVisit = this.$el.querySelector('.js-jobCalendarInputMultiDateVisit');
        this.$monthNavNext = this.$el.querySelector('.js-jobCalendarMonthNavNext');
        this.$monthNavPrev = this.$el.querySelector('.js-jobCalendarMonthNavPrev');
        this.$container = this.$el.querySelector('.js-jobCalendarContainer');
        this.activeMonth = null;
        this.activeYear = null;
        this.dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        this.mode = 'job';
        this.monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        // Initialiseer multi_date arrays
        this.multiDate = this.parseMultiDate(this.$inputMultiDate.value);
        this.multiDateCancel = this.parseMultiDate(this.$inputMultiDateCancel.value);
        this.multiDateLoad = this.parseMultiDate(this.$inputMultiDateLoad.value);
        this.multiDateVisit = this.parseMultiDate(this.$inputMultiDateVisit.value);

        const $config = this.$el.querySelector('script[job-calendar-config]');

        if ($config) {
            const config = JSON.parse($config.textContent);

            if (config && config.dayNames && config.monthNames) {
                this.activeMonth = config.activeMonth - 1;
                this.activeYear = config.activeYear;
                this.dayNames = config.dayNames;
                this.monthNames = config.monthNames;
            }
        }

        // Set mode
        const $activeMode = this.$el.querySelector(`.js-jobCalendarMode[aria-selected=true]`);

        if ($activeMode) {
            this.mode = $activeMode.dataset.jobCalendarMode;
            this.$el.dataset.jobCalendarMode = this.mode;
        }

        // Set active month
        this.month = new Date(this.activeYear, this.activeMonth, 1);

        this.renderCalendars();
        this.initListeners();
    }

    /**
     * Geef de datum terug in YYYY-MM-DD format.
     * 
     * @param {Date} date Datum object.
     */
    formatDateISO(date) {
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    }

    /**
     * Geef de datum terug in DD/MM/YYYY format.
     * 
     * @param {Date} date Datum object.
     */
    formatDateLegacy(date) {
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    initListeners() {
        this.$$mode.forEach(($mode) => {
            $mode.addEventListener('click', this.onModeClick.bind(this));
        });

        this.$monthNavNext.addEventListener('click', this.onMonthNextClick.bind(this));
        this.$monthNavPrev.addEventListener('click', this.onMonthPrevClick.bind(this));
    }

    onDateClick(e) {
        this.toggleDate(e.currentTarget.dataset.dateLegacy);
        e.preventDefault();
    }

    onModeClick(e) {
        this.mode = e.currentTarget.dataset.jobCalendarMode;
        this.$el.dataset.jobCalendarMode = this.mode;
    }

    onMonthNextClick() {
        this.$container.innerHTML = '';
        this.month = new Date(this.month.getFullYear(), this.month.getMonth() + 1, 1);
        this.renderCalendars();
    }

    onMonthPrevClick() {
        this.$container.innerHTML = '';
        this.month = new Date(this.month.getFullYear(), this.month.getMonth() - 1, 1);
        this.renderCalendars();
    }

    /**
     * Verwerk een komma-gescheiden multidate string naar een gesorteerde array.
     * 
     * @param {String} multiDate 
     * @returns {Array}
     */
    parseMultiDate(multiDate) {
        if (multiDate.trim() === '') {
            return [];
        }

        const multiDateArray = multiDate.split(',');
        const response = [];

        for (const date of multiDateArray) {
            const dateParts = date.split('/');
            const dateObj = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
            response.push(this.formatDateLegacy(dateObj));
        }

        response.sort();
        return response;
    }

    renderCalendar(month, year) {
        // Maak de container
        const $calendar = document.createElement('div');
        $calendar.className = 'jobCalendar__calendar';

        // Maak de <h3>
        const $heading = document.createElement('h3');
        $heading.textContent = this.monthNames[month] + ' ' + year;
        $calendar.appendChild($heading);

        // Maak de kalender tabel
        const $table = document.createElement('table');
        $table.setAttribute('cellspacing', '2');

        // Maak de <thead> met de dagen van de week
        const $thead = document.createElement('thead');
        const $dayNamesRow = document.createElement('tr');

        for (let i = 0; i < 7; i++) {
            const $dayNameCell = document.createElement('th');
            $dayNameCell.setAttribute('scope', 'col');
            $dayNameCell.textContent = this.dayNames[i];
            $dayNamesRow.appendChild($dayNameCell);
        }

        $thead.appendChild($dayNamesRow);
        $table.appendChild($thead);

        // Maak de <tbody> met de weken van de maand
        const $tbody = document.createElement('tbody');

        // Bepaal eerste dag van deze maand
        const firstDay = new Date(year, month, 1);

        // Begin op maandag (sorry, dit % trucje komt gewoon van internet haha)
        const startingDay = (firstDay.getDay() + 6) % 7;

        // Bepaal het aantal dagen in deze maand
        const daysInMonth = new Date(year, month + 1, 0).getDate();

        let date = 1;

        // Maak rijen voor de kalender
        for (let i = 0; i < 6; i++) {
            const $tr = document.createElement('tr');
            let rowHasDays = false;

            // Maak kolommen voor de dagen van de week
            for (let j = 0; j < 7; j++) {
                const $td = document.createElement('td');

                if (i === 0 && j < startingDay) {
                    // Lege kolom voor de eerste dag van de maand
                    $td.textContent = '';
                } else if (date > daysInMonth) {
                    // Lege kolom na de laatste dag van de maand
                    $td.textContent = '';
                } else {
                    // Kolom met datum
                    const $btn = document.createElement('button');
                    const dateObj = new Date(year, month, date);
                    const dateISO = this.formatDateISO(dateObj);
                    const dateLegacy = this.formatDateLegacy(dateObj);
                    $btn.addEventListener('click', this.onDateClick.bind(this));
                    $btn.dataset.dateIso = dateISO;
                    $btn.dataset.dateLegacy = dateLegacy;
                    $btn.textContent = date;
                    $btn.type = 'button';
                    $td.appendChild($btn);


                    rowHasDays = true;
                    date++;
                }

                $tr.appendChild($td);
            }

            if (rowHasDays) {
                $tbody.appendChild($tr);
            }
        }

        $table.appendChild($tbody);
        $calendar.appendChild($table);
        this.$container.appendChild($calendar);
    }

    renderCalendars() {
        const nextMonth = new Date(this.month.getFullYear(), this.month.getMonth() + 1, 1);

        this.renderCalendar(this.month.getMonth(), this.month.getFullYear());
        this.renderCalendar(nextMonth.getMonth(), nextMonth.getFullYear());
        this.updateActiveClasses();
    }

    /**
     * Voeg een datum toe aan de juiste array.
     * 
     * @param {String} date Datum in DD/MM/YYYY format.
     */
    toggleDate(date) {
        let $input;
        let array;

        switch (this.mode) {
            case 'load':
                $input = this.$inputMultiDateLoad;
                array = 'multiDateLoad';
                break;

            case 'cancel':
                $input = this.$inputMultiDateCancel;
                array = 'multiDateCancel';
                break;

            case 'visit':
                $input = this.$inputMultiDateVisit;
                array = 'multiDateVisit';
                break;

            default:
                $input = this.$inputMultiDate;
                array = 'multiDate';
        }

        if (this[array].includes(date)) {
            // Verwijder datum uit array
            this[array] = this[array].filter((d) => d !== date);
        } else {
            // Voeg datum toe aan array
            this[array].push(date);
        }

        // Update de input waarde
        const sorted = this.parseMultiDate(this[array].join(','));
        $input.value = sorted.join(',');

        // Update de actieve staat van de kalender knoppen
        this.updateActiveClasses();
    }

    /**
     * Update de actieve classes op de knoppen.
     */
    updateActiveClasses() {
        const $$btn = this.$el.querySelectorAll('button[data-date-legacy]');

        $$btn.forEach(($btn) => {
            $btn.classList.toggle('is-job', this.multiDate.includes($btn.dataset.dateLegacy));
            $btn.classList.toggle('is-load', this.multiDateLoad.includes($btn.dataset.dateLegacy));
            $btn.classList.toggle('is-cancel', this.multiDateCancel.includes($btn.dataset.dateLegacy));
            $btn.classList.toggle('is-visit', this.multiDateVisit.includes($btn.dataset.dateLegacy));
        });
    }
}