export default class PickList {
    $el;
    $content;
    isLoaded;

    constructor($el) {
        this.$el = $el;
        this.$content = this.$el.querySelector('.js-pickListContent');
        this.isLoaded = false;

        this.initListeners();
    }

    initListeners() {
        const $tabs = this.$el.closest('.js-tabs');

        if ($tabs) {
            $tabs.addEventListener('tabs.activate.tab-pl', this.load.bind(this));
        }
    }

    async load() {
        if (this.isLoaded) {
            return;
        }

        const form = this.$el.querySelector('.js-pickListForm');
        const formData = new FormData(form);

        const response = await fetch('/Request_Handler.php', {
            body: formData,
            method: "post"
        });
        const html = await response.text();
        this.$content.innerHTML = html;
        this.isLoaded = true;
    }
}
