import Cookies from 'js-cookie';

export default class Tabs {
    $el;
    $$tab;

    constructor($el) {
        this.$el = $el;
        this.$$tab = this.$el.querySelectorAll(`[role='tab']`)

        this.initListeners();
        this.dispatchActivateEvent();

        const planner_last_tab = Cookies.get('planner_last_tab');
        if (planner_last_tab) {
            const $tab = document.getElementById(planner_last_tab);
            const $tabPanel = document.getElementById($tab.getAttribute('aria-controls'));

            this.$el.querySelectorAll(`[role='tab']`).forEach($tab => {
                if ($tab.closest('.js-tabs') === this.$el) {
                    $tab.setAttribute('aria-selected', false);
                }
            });

            this.$el.querySelectorAll(`[role='tabpanel']`).forEach($tabPanel => {
                if ($tab.closest('.js-tabs') === this.$el) {
                    $tabPanel.setAttribute('hidden', true);
                }
            });

            $tab.setAttribute('aria-selected', true);

            if ($tabPanel) {
                $tabPanel.removeAttribute('hidden');
            }

            this.dispatchActivateEvent();
        }
    }

    dispatchActivateEvent() {
        const $activeTab = this.$el.querySelector(`[role=tab][aria-selected=true]`);

        if ($activeTab) {
            const activeTabId = $activeTab.id ?? null;

            this.$el.dispatchEvent(new CustomEvent(`tabs.activate.${activeTabId}`, {bubbles: true}));
            this.$el.dispatchEvent(new CustomEvent(`tabs.activate`, {
                detail: {
                    id: activeTabId
                }
            }, {bubbles: true}));
        }
    }

    initListeners() {
        this.$$tab.forEach($tab => {
            if ($tab.closest('.js-tabs') === this.$el) {
                $tab.addEventListener('pointerdown', this.onTabPointerDown.bind(this));
            }
        });
    }

    onTabPointerDown(e) {
        const $tab = e.currentTarget;

        Cookies.set('planner_last_tab', $tab.getAttribute('id'), {expires: 7, sameSite: 'strict'});

        const $tabPanel = document.getElementById($tab.getAttribute('aria-controls'));

        this.$el.querySelectorAll(`[role='tab']`).forEach($tab => {
            if ($tab.closest('.js-tabs') === this.$el) {
                $tab.setAttribute('aria-selected', false);
            }
        });

        this.$el.querySelectorAll(`[role='tabpanel']`).forEach($tabPanel => {
            if ($tab.closest('.js-tabs') === this.$el) {
                $tabPanel.setAttribute('hidden', true);
            }
        });

        $tab.setAttribute('aria-selected', true);

        if ($tabPanel) {
            $tabPanel.removeAttribute('hidden');
        }

        this.dispatchActivateEvent();
        e.preventDefault();
    }
}
