import { Loader } from '@googlemaps/js-api-loader';

const loader = new Loader({
    apiKey: 'AIzaSyA9LEI5HFSAheKQKZRHjI3gFgOJNdG4WnU',
    version: 'weekly'
});

export default class Maps {
    $el;
    $header;
    isExpanded = false;

    constructor($el) {
        this.$el = $el;

        this.$$map = $el.querySelectorAll('.mapshow');
        this.$$adressync = $el.querySelectorAll('.adressync');
        this.placeid = $el.dataset.placeid ?? null;
        this.placelat = parseFloat($el.dataset.placelat) ?? 50.8883771;
        this.placelng = parseFloat($el.dataset.placelng) ?? 4.1753249;

        this.position = {
            lat: this.placelat,
            lng: this.placelng
        };

        this.placetitle = $el.dataset.placetitle ?? null;
        this.initListeners();
    }

    initListeners() {
        // Ga door alle elementen met mapshow
        for (const $map of this.$$map) {
            loader.load().then(async () => {
                const { Map } = await google.maps.importLibrary('maps');
                const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');

                const map = new google.maps.Map($map, {
                    zoom: 14,
                    center: this.position,
                    mapId: 'cf1aa6399bcffa08', // Map ID is required for advanced markers.
                });

                // The advanced marker, positioned at position
                const marker = new google.maps.marker.AdvancedMarkerElement({
                    map,
                    position: this.position,
                    title: this.placetitle,
                });

            });
        }

        for (const $adressync of this.$$adressync) {
            $adressync.addEventListener('click', this.onClick.bind(this), false);
        }
    }

    onClick(e) {
        e.preventDefault();
        
        var trans = {};

        trans['inp_adres'] = $('#inp_adres').val();
        trans['inp_naam'] = $('#inp_naam').val();
        trans['inp_fase'] = $('#inp_fase').val();
        trans['werf_id'] = $('#werf_id').val();
        $.ajax({
            url: 'Request_Handler.php?REQ=141',
            method: 'POST',
            data: trans,
            success: function (json) {
                //maak array van antwoord
                //var json = $.parseJSON(response);
                
                $('#inp_adres').val(json['googlemaps']['formatted_address']);
                
                if (json['Error'] == 'false') {
                    const position = {lat: json['googlemaps']['geometry']['location']['lat'], lng: json['googlemaps']['geometry']['location']['lng']};

                    const map = new google.maps.Map(document.getElementById('map'), {
                        zoom: 14,
                        center: position,
                        mapId: 'cf1aa6399bcffa08', // Map ID is required for advanced markers.
                    });

                    // The advanced marker, positioned at position
                    const marker = new google.maps.marker.AdvancedMarkerElement({
                        map,
                        position: position,
                        title: json['Naam'],
                    });
                }
            }
        });

    }

}
