export default class Box {
    $el;
    $header;
    isExpanded = false;

    constructor($el) {
        this.$el = $el;
        this.$header = this.$el.querySelector('.js-boxHeader');

        this.initListeners();
    }

    initListeners() {
        if (this.$header) {
            this.$header.addEventListener('pointerdown', this.onToggle.bind(this));
        }
    }

    onToggle(e) {
        e.preventDefault();
        this.isExpanded = !this.isExpanded;
        this.$el.setAttribute('aria-expanded', this.isExpanded);
    }
}
