export default class ScannerModal {
    $el;
    $scanner;

    constructor($el) {
        this.$el = $el;
        this.$scanner = this.$el.querySelector('.js-scanner');
        this.initListeners();
    }

    initListeners() {
        this.$el.addEventListener('modal:close', this.onModalClose.bind(this), false);
        this.$el.addEventListener('modal:open', this.onModalOpen.bind(this), false);
        this.$scanner.addEventListener('scanner:success', this.onScanSuccess.bind(this), false);
    }

    onModalClose() {
        this.$scanner.dispatchEvent(new CustomEvent('stop'));
    }

    onModalOpen() {
        this.$scanner.dispatchEvent(new CustomEvent('start'));
    }

    async onScanSuccess(e) {
        const body = new FormData();
        body.append('LAT', e.detail.lat);
        body.append('LONG', e.detail.lng);
        body.append('scan', e.detail.result);

        const response = await fetch('Request_Handler.php?REQ=79', {
            method: 'POST',
            body: body
        });
        const json = await response.json();

        if (json[0].toLowerCase() === 'redirect') {
            if (json['id']) {
                window.location.href = '/index.php?action=' + json['url'] + '&id=' + json['id'] + '&stamp=' + Date.now();
            }
            if (json['SN']) {
                window.location.href = '/index.php?action=' + json['url'] + '&RB_Zend_SN=' + json['SN'] + '&stamp=' + Date.now();
            }
            if (json['ID']) {
                window.location.href = '/index.php?action=' + json['url'] + '&id=' + json['ID'] + '&stamp=' + Date.now();
            }
            if (json['LINK']) {
                window.location.href = json['LINK'] + '&stamp=' + Date.now();
            }
        }

        if (json[0].toLowerCase() === 'action') {
            if (json['Action'] == 'Input_Part') {
                //$response['Action'] = "Input_Part";
                QR_ID = json['id'];
                Input_Part(response);
            }
        }
    }
}
