export default class Menu {
    constructor($el) {
        this.$el = $el;
        this.$$submenu = $el.querySelectorAll('.js-menuSubmenu');
        this.$$submenuToggle = $el.querySelectorAll('[aria-controls]');
        this.collapseOthers = true;

        this.updateSubmenuHeights();
        this.initListeners();
    }

    initListeners() {
        for (const $toggle of this.$$submenuToggle) {
            $toggle.addEventListener('click', this.onSubmenuToggleClick.bind(this), false);
        }

        // Update submenu heights again when fonts have loaded
        window.addEventListener('load', this.updateSubmenuHeights.bind(this), false);
    }

    onSubmenuToggleClick(e) {
        const id = e.target.getAttribute('aria-controls');
        const isExpanded = e.target.getAttribute('aria-expanded') == 'true';

        for (const $toggle of this.$$submenuToggle) {
            if ($toggle.getAttribute('aria-controls') === id) {
                $toggle.setAttribute('aria-expanded', !isExpanded);
            } else {
                if (this.collapseOthers) {
                    $toggle.setAttribute('aria-expanded', false);
                }
            }
        }

        e.preventDefault();
    }

    updateSubmenuHeights() {
        for (const $submenu of this.$$submenu) {
            $submenu.style.height = 'auto';
            $submenu.style.setProperty('--height', `${$submenu.offsetHeight}px`);
            $submenu.style.height = '';
        }
    }
}
