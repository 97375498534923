import QrScanner from 'qr-scanner';

// Documentatie: https://github.com/nimiq/qr-scanner

export default class Scanner {
    $cameraSelectList;
    $el;
    $file;
    $inputLat;
    $inputLng;
    $toggleTorch;
    isAutostart;
    isLocationRequired;
    lat;
    lng;
    options;
    result;
    scanner;

    constructor($el, options = {}) {
        this.$el = $el;
        this.options = options;

        this.$cameraSelect = this.$el.querySelector('.js-scannerCameraSelect');
        this.$cameraSelectList = this.$el.querySelector('.js-scannerCameraSelectList');
        this.$file = this.$el.querySelector('input[type="file"]');
        this.$inputLat = this.$el.querySelector('input[name="lat"]');
        this.$inputLng = this.$el.querySelector('input[name="lng"]');
        this.$inputResult = this.$el.querySelector('input[name="result"]');
        this.$toggleTorch = this.$el.querySelector('.js-scannerToggleTorch');
        this.isAutostart = this.$el.dataset.scannerAutostart === 'true';
        this.isLocationRequired = this.$el.dataset.scannerLocationRequired === 'true';
        this.lat = null;
        this.lng = null;
        this.result = null;

        // Initialiseer de scanner
        this.scanner = new QrScanner(
            this.$el.querySelector('video'),
            this.onScanSuccess.bind(this),
            {
                highlightScanRegion: true,
                maxScansPerSecond: 10,
                returnDetailedScanResult: true,
            },
        );

        this.initListeners();

        if (this.isAutostart) {
            this.start();
        }
    }

    initListeners() {
        this.$file.addEventListener('change', this.onFileChange.bind(this));
        this.$toggleTorch.addEventListener('click', this.onToggleTorch.bind(this));
        this.$cameraSelectList.addEventListener('change', this.onCameraChange.bind(this));

        this.$el.addEventListener('start', () => this.start(), false);
        this.$el.addEventListener('stop', () => this.stop(), false);
    }

    onCameraChange(e) {
        this.setCamera(e.target.value);
    }

    onFileChange(e) {
        const file = e.target.files[0];

        if (!file) {
            return;
        }

        QrScanner.scanImage(file, { returnDetailedScanResult: true })
            .then(result => this.onScanSuccess(result))
            .catch(e => this.onScanError(e));
    }

    onGeoError() {
        alert('U dient toegang te geven tot uw locatie om de scanner te gebruiken. Probeer de pagina opnieuw te laden.');
    }

    onGeoSuccess(position) {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;

        this.$inputLat.value = this.lat = lat;
        this.$inputLng.value = this.lng = lng;

        this.startScanner();
    }

    onScanError(error) {
    }

    onScanSuccess(result) {
        this.$inputResult.value = this.result = result.data;

        this.$el.dispatchEvent(new CustomEvent('scanner:success', {
            detail: {
                lat: this.lat,
                lng: this.lng,
                result: this.result,
            }
        }));

        this.scanner.stop();
    }

    async onToggleTorch() {
        this.$el.classList.toggle('is-torchOn');
        this.scanner.toggleFlash();
    }

    async setCamera(cameraId) {
        this.scanner.setCamera(cameraId);
    }

    start() {
        if (this.isLocationRequired) {
            if (navigator.geolocation) {
                this.$el.classList.add('is-locationPending');
                navigator.geolocation.getCurrentPosition(this.onGeoSuccess.bind(this), this.onGeoError.bind(this));
            } else {
                alert('Geolocation is not supported by this browser.');
            }
        } else {
            this.startScanner();
        }
    }

    async startScanner() {
        this.scanner.start().then(() => {
            QrScanner.listCameras(true).then(cameras => {
                if (cameras.length > 1) {
                    this.$cameraSelect.dataset.scannerCameraCount = cameras.length;
                    this.$el.classList.add('has-multipleCameras');
    
                    for (const camera of cameras) {
                        const $option = document.createElement('option');
                        $option.textContent = camera.label.replace(/\([^\)]+\)/, '').trim();
                        $option.value = camera.id;
                        this.$cameraSelectList.appendChild($option);
                    }                
                }
            });

            this.updateFlashAvailability();
            this.$el.classList.remove('is-locationPending');
        });
    }

    stop() {
        this.scanner.stop();
    }

    updateFlashAvailability() {
        this.scanner.hasFlash().then(hasFlash => {
            this.$el.classList.toggle('has-torch', hasFlash);
        });
    };
}