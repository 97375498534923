export default class SyncSalesforce {
    $el;

    constructor($el) {
        this.$el = $el;
        this.initListeners();
    }

    initListeners() {
        this.$el.addEventListener('click', this.onClick.bind(this), false);
    }

    onClick(e) {
        e.preventDefault();
        const url = this.$el.href ?? this.$el.dataset.xhrModalUrl ?? null;

        fetch(url)
                .then(response => response.text())
                .then(data => {
                    var obj = JSON.parse(data);

                    $("#" + obj['id']).empty();
                    for (var i in obj['datalist']) {
                        $("<option/>").html(obj['datalist'][i].value).appendTo("#" + obj['id']);
                    }
                    this.$el.focus();

                    document.dispatchEvent(new CustomEvent('toast', {
                        detail: {
                            message: obj['antwoord'],
                            type: "success"
                        }
                    }));
                })
                .catch(error => {
                    document.dispatchEvent(new CustomEvent('toast', {
                        detail: {
                            message: error,
                            type: "error"
                        }
                    }));
                });

    }
}

