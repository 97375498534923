export default class Planner {
    constructor($el) {
        this.$el = $el;
        this.$$commentForm = this.$el.querySelectorAll('.js-plannerCommentForm');

        this.initListeners();
    }

    initListeners() {
        this.$$commentForm.forEach(($commentForm) => {
            const $textarea = $commentForm.querySelector('textarea');

            if ($textarea) {
                $textarea.addEventListener('blur', this.onCommentBlur.bind(this), false);
            }
        });
    }

    async onCommentBlur(e) {
        const $textarea = e.target;
        const $form = $textarea.closest('form');

        if ($form) {
            const $id = $form.querySelector('input[name="id"]');
            const body = new FormData($form);
            const url = $form.action;
            const method = $form.method;
    
            const response = await fetch(url, { body, method });
            const json = await response.json();
    
            // Schrijf eventueel nieuwe ID terug naar het formulier
            $id.value = json.id ?? '';
        }

        console.log(json);
    }
}
