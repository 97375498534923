export default class Password {
    constructor($el) {
        this.$el = $el;
        this.$input = this.$el.querySelector('input[type="password"]');
        this.$toggle = this.$el.querySelector('.js-passwordToggle');

        this.initListeners();
    }

    initListeners() {
        this.$toggle.addEventListener('click', this.onToggleClick.bind(this));
    }

    onToggleClick(e) {
        this.$el.classList.toggle('is-unmasked');
        this.$input.type = (this.$input.type === 'password') ? 'text' : 'password';

        e.preventDefault();
    }
}
